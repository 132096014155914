import React from "react";
import styles from "./landingPageMobCss/aboutus.module.css";
import img1 from "../imagesMobImg/aboutMob/Frame 2610951.png";
import img2 from "../imagesMobImg/aboutMob/Frame 2610952.png";
import img3 from "../imagesMobImg/aboutMob/Frame 2610953.png";

const AboutUs = () => {
  return (
    <>
      <div className={styles.container}>     
        <div className={styles.containn}>
        <div className={styles.heading}>See who’s talking <br /> about us</div>
          <div style={{ display: "flex", overflowX: "scroll" }}>
          <a href="https://www.constructionworld.in/latest-construction-news/real-estate-news/idesign.market-has-developed-software-for-interior-designers/33349" target='blank'> <img src={img1} alt="" className={styles.imgs}/></a> 
            <a href="https://www.zeebiz.com/small-business/news-tech-startup-idesignmarket-launches-software-for-interior-designing-professionals-178661" target='blank'> <img src={img2} alt="" className={styles.imgs}/></a> 
            <a href="https://www.deccanherald.com/brandspot/pr-spot/navneet-jhamb-joins-as-advisor-to-tech-startup-idesignmarket-1106972.html" target='blank'><img src={img3} alt="" className={styles.imgs}/></a> 
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
