import React, { useEffect, useState } from 'react'
import side from './VendorImgs/side.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import info from './VendorImgs/info.svg'
import { Modal, Offcanvas } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import axios from 'axios'
import closeCanvasBar from "./VendorImgs/closeCanvasBar.svg";

const EditRateListMob = () => {
    const navigate = useNavigate()
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    // const profileData = useSelector((state) => state.addToCartReducer.profileData)
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [profileData, setProfileData] = useState([])
    const [showId, setShowId] = useState()
    const [ofArr, setOfArr] = useState([])
    const [isSave, setIsSave] = useState(false)
    const [venData, setVenData] = useState({})

    const profileInfo = async () => {
        const remM = await axios.get("https://pro-api.idesign.market/user/profile", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        })
        setProfileData(remM?.data?.data)
    }

    useEffect(() => {
        profileInfo()
    }, [])

    useEffect(() => {
        setVenData({
            tileFlooringFixing: profileData?.contractorVendorFees?.tileFlooringFixing?.rate ? profileData?.contractorVendorFees?.tileFlooringFixing?.rate : "",

            wallTilesFixing: profileData?.contractorVendorFees?.wallTilesFixing?.rate ? profileData?.contractorVendorFees?.wallTilesFixing?.rate : "",

            dadoPlasterForWallTiles: profileData?.contractorVendorFees?.dadoPlasterForWallTiles?.rate ? profileData?.contractorVendorFees?.dadoPlasterForWallTiles?.rate : "",

            plainCementConcrete: profileData?.contractorVendorFees?.plainCementConcrete?.rate ? profileData?.contractorVendorFees?.plainCementConcrete?.rate : "",

            brickworkHalf: profileData?.contractorVendorFees?.brickworkHalf?.rate ? profileData?.contractorVendorFees?.brickworkHalf?.rate : "",

            brickworkFull: profileData?.contractorVendorFees?.brickworkFull?.rate ? profileData?.contractorVendorFees?.brickworkFull?.rate : "",

            plastering: profileData?.contractorVendorFees?.plastering?.rate ? profileData?.contractorVendorFees?.plastering?.rate : "",

            popFalseCeiling: profileData?.contractorVendorFees?.popFalseCeiling?.rate ? profileData?.contractorVendorFees?.popFalseCeiling?.rate : "",

            popCove: profileData?.contractorVendorFees?.popCove?.rate ? profileData?.contractorVendorFees?.popCove?.rate : "",

            popPunning: profileData?.contractorVendorFees?.popPunning?.rate ? profileData?.contractorVendorFees?.popPunning?.rate : "",

            gypsumFalseCeiling: profileData?.contractorVendorFees?.gypsumFalseCeiling?.rate ? profileData?.contractorVendorFees?.gypsumFalseCeiling?.rate : "",

            gypsumCove: profileData?.contractorVendorFees?.gypsumCove?.rate ? profileData?.contractorVendorFees?.gypsumCove?.rate : "",

            woodenFalseCeiling: profileData?.contractorVendorFees?.woodenFalseCeiling?.rate ? profileData?.contractorVendorFees?.woodenFalseCeiling?.rate : "",

            royalPaint: profileData?.contractorVendorFees?.royalPaint?.rate ? profileData?.contractorVendorFees?.royalPaint?.rate : "",

            premiumEmulsion: profileData?.contractorVendorFees?.premiumEmulsion?.rate ? profileData?.contractorVendorFees?.premiumEmulsion?.rate : "",

            wallPanellingWithLaminate: profileData?.contractorVendorFees?.wallPanellingWithLaminate?.rate ? profileData?.contractorVendorFees?.wallPanellingWithLaminate?.rate : "",

            wallPanellingWithLaminateWithHardwoodFrame: profileData?.contractorVendorFees?.wallPanellingWithLaminateWithHardwoodFrame?.rate ? profileData?.contractorVendorFees?.wallPanellingWithLaminateWithHardwoodFrame?.rate : "",

            wallPanellingVeneer: profileData?.contractorVendorFees?.wallPanellingVeneer?.rate ? profileData?.contractorVendorFees?.wallPanellingVeneer?.rate : "",

            mirrorPanellingPlainRectangularWithBevelled: profileData?.contractorVendorFees?.mirrorPanellingPlainRectangularWithBevelled?.rate ? profileData?.contractorVendorFees?.mirrorPanellingPlainRectangularWithBevelled?.rate : "",

            mirrorPanellingBeveledBorderAndColoredMirror: profileData?.contractorVendorFees?.mirrorPanellingBeveledBorderAndColoredMirror?.rate ? profileData?.contractorVendorFees?.mirrorPanellingBeveledBorderAndColoredMirror?.rate : "",

            doorWithLaminateFinish: profileData?.contractorVendorFees?.doorWithLaminateFinish?.rate ? profileData?.contractorVendorFees?.doorWithLaminateFinish?.rate : "",

            doorChaukathFrame: profileData?.contractorVendorFees?.doorChaukathFrame?.rate ? profileData?.contractorVendorFees?.doorChaukathFrame?.rate : "",

            lookingMirrorBathrooms: profileData?.contractorVendorFees?.lookingMirrorBathrooms?.rate ? profileData?.contractorVendorFees?.lookingMirrorBathrooms?.rate : "",

            shiftingPointsOneMetre: profileData?.contractorVendorFees?.shiftingPointsOneMetre?.rate ? profileData?.contractorVendorFees?.shiftingPointsOneMetre?.rate : "",

            shiftingPointsOneToFiveMetre: profileData?.contractorVendorFees?.shiftingPointsOneToFiveMetre?.rate ? profileData?.contractorVendorFees?.shiftingPointsOneToFiveMetre?.rate : "",

            newPowerPoints: profileData?.contractorVendorFees?.newPowerPoints?.rate ? profileData?.contractorVendorFees?.newPowerPoints?.rate : "",

            falseCeilingElectricalWiring: profileData?.contractorVendorFees?.falseCeilingElectricalWiring?.rate ? profileData?.contractorVendorFees?.falseCeilingElectricalWiring?.rate : "",

            lightInstallationOnWalls: profileData?.contractorVendorFees?.lightInstallationOnWalls?.rate ? profileData?.contractorVendorFees?.lightInstallationOnWalls?.rate : "",

            fanInstallation: profileData?.contractorVendorFees?.fanInstallation?.rate ? profileData?.contractorVendorFees?.fanInstallation?.rate : "",

            completeElectricalWiring: profileData?.contractorVendorFees?.completeElectricalWiring?.rate ? profileData?.contractorVendorFees?.completeElectricalWiring?.rate : "",

            acCopperPiping: profileData?.contractorVendorFees?.acCopperPiping?.rate ? profileData?.contractorVendorFees?.acCopperPiping?.rate : "",

            acDrainPipe: profileData?.contractorVendorFees?.acDrainPipe?.rate ? profileData?.contractorVendorFees?.acDrainPipe?.rate : "",

            acInstallation: profileData?.contractorVendorFees?.acInstallation?.rate ? profileData?.contractorVendorFees?.acInstallation?.rate : "",
        })
    }, [profileData])

    const handleInput = (e) => {
        if (e.target.value?.length <= 4 && e.target?.value >= 0) {
            setVenData((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    }
    const dataArr = [
        {
            id: 1,
            name: "Tile Flooring Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: venData.tileFlooringFixing,
            name2: "tileFlooringFixing"
        },
        {
            id: 2,
            name: "Wall Tiles Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: venData.wallTilesFixing,
            name2: "wallTilesFixing"
        },
        {
            id: 3,
            name: "Dado Plaster for Wall Tiles",
            description: " Dado Plaster to be done on Wall for Installation of Tiles. (Tiles to be provided at site by client)",
            unit: "Sq.ft",
            value: venData.dadoPlasterForWallTiles,
            name2: "dadoPlasterForWallTiles"
        },
        {
            id: 4,
            name: "PCC",
            description: "(Plain Cement Concrete) in toilets/ kitchen or any other floor as required upto 2 inch thick.",
            unit: "Sq.ft",
            value: venData.plainCementConcrete,
            name2: "plainCementConcrete"

        },
        {
            id: 5,
            name: "Brickwork",
            description: "Making of half brick wall 115mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre",
            unit: "Sq.ft",
            value: venData.brickworkHalf,
            name2: "brickworkHalf"
        },
        {
            id: 6,
            name: "Brickwork",
            description: " Making of full brick wall 230 mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre.",
            unit: "Sq.ft",
            value: venData.brickworkFull,
            name2: "brickworkFull"
        },
        {
            id: 7,
            name: "Plastering",
            description: "Providing & Applying 10-12mm thick plaster in ratio 1:4 (1 Cement 4 Sand)",
            unit: "Sq.ft",
            value: venData.plastering,
            name2: "plastering"
        },
        {
            id: 8,
            name: "Pop False Ceiling",
            description: "Providing and fixing False ceiling made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: venData.popFalseCeiling,
            name2: "popFalseCeiling"
        },
        {
            id: 9,
            name: "POP Cove",
            description: "(Measurement taken 2 times as per internal + external cove) providing and fixing False ceiling Cove made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: venData.popCove,
            name2: "popCove"
        },

        {
            id: 10,
            name: "Pop Punning on walls & ceiling",
            description: " ",
            unit: "Sq.ft",
            value: venData.popPunning,
            name2: "popPunning"

        },
        {
            id: 11,
            name: "Gypsum False ceiling",
            description: "Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework ",
            unit: "Sq.ft",
            value: venData.gypsumFalseCeiling,
            name2: "gypsumFalseCeiling"
        },
        {
            id: 12,
            name: "Gypsum Cove",
            description: " Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework",
            unit: "Rft",
            value: venData.gypsumCove,
            name2: "gypsumCove"
        },
        {
            id: 13,
            name: "Wooden False Ceiling",
            description: "Made out of 12 mm plywood and 4 mm Veneer with a hardwood frame. Basic Cost of Veneer Rs 60 per sqft. Includes Melamine Polish. Frame to be treated with anti termite oil.  ",
            unit: "Sq.ft",
            value: venData.woodenFalseCeiling,
            name2: "woodenFalseCeiling"
        },
        {
            id: 14,
            name: "Royal Paint (Asian Paints)",
            description: " Providing the application of 2 coats of putty and 3 coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: venData.royalPaint,
            name2: "royalPaint"
        },
        {
            id: 15,
            name: "Premium Emulsion (Asian Paints)",
            description: " Providing the application of 2 coats of putty and coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: venData.premiumEmulsion,
            name2: "premiumEmulsion"
        },
        {
            id: 16,
            name: "Wall panelling with laminate",
            description: "Made out of plywood with laminate finished. Laminate fixed on 16mm Plywood. Laminates of SF and HGL only of merino or century (without hardwood frame)",
            unit: "Sq.ft",
            value: venData.wallPanellingWithLaminate,
            name2: "wallPanellingWithLaminate"
        },
        {
            id: 17,
            name: "Wall Panelling with laminate with hardwood frame",
            description: "  Wall Panelling to be done using 12mm plyboard with Laminate finish on a hardwood frame. Frame to be treated with anti termite oil. Cove to be given if required. Laminates of SF and HGL only of merino or century ",
            unit: "Sq.ft",
            value: venData.wallPanellingWithLaminateWithHardwoodFrame,
            name2: "wallPanellingWithLaminateWithHardwoodFrame"
        },
        {
            id: 18,
            name: "Wall Panelling (Veneer)",
            description: " Wall Panelling to be done using 18mm plyboard with Veneer & Polish (Basic Cost of Veneer Rs 60 to Rs 75 per Sqft). Design to be straight lined.",
            unit: "Sq.ft",
            value: venData.wallPanellingVeneer,
            name2: "wallPanellingVeneer"
        },
        {
            id: 19,
            name: "Mirror Panelling (Plain rectangular with bevelled as per design)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Plain Mirror. The mirror to be cut in rectangular shape with bevelling",
            unit: "Sq.ft",
            value: venData.mirrorPanellingPlainRectangularWithBevelled,
            name2: "mirrorPanellingPlainRectangularWithBevelled"
        },
        {
            id: 20,
            name: "Mirror Panelling (Beveled/Border and colored mirror)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Beveled/border Plain/Colored Mirror. Glass: St Gobains/ Modi",
            unit: "Sq.ft",
            value: venData.mirrorPanellingBeveledBorderAndColoredMirror,
            name2: "mirrorPanellingBeveledBorderAndColoredMirror"
        },
        {
            id: 21,
            name: "Door with Laminate finish",
            description: "(Cost of locks & handle extra as per selection) Door Size to be a maximum of 900mm x 2250mm. Brand: Bhutan Tuff Doors. Hinges: Hetich ",
            unit: "Nos",
            value: venData.doorWithLaminateFinish,
            name2: "doorWithLaminateFinish"
        },
        {
            id: 22,
            name: "Door chaukath/frame",
            description: "of size 5 inch x 2.5 inch of Chaap/ Ivory coast with melamine polish. Chaukhat to be treated with anti termite oil",
            unit: "Nos",
            value: venData.doorChaukathFrame,
            name2: "doorChaukathFrame"
        },
        {
            id: 23,
            name: "Looking Mirror",
            description: " in Bathrooms/ frameless on ply with bevelling as required. To be fixed on 12 mm waterproof ply . Mirror Glass: St Gobain/Modi",
            unit: "Nos",
            value: venData.lookingMirrorBathrooms,
            name2: "lookingMirrorBathrooms"
        },
        {
            id: 24,
            name: "Wires: Polycab/Havells (upto 1 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: venData.shiftingPointsOneMetre,
            name2: "shiftingPointsOneMetre"
        },
        {
            id: 25,
            name: "Wires: Polycab/Havells (upto 1 metre to 5 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: venData.shiftingPointsOneToFiveMetre,
            name2: "shiftingPointsOneToFiveMetre"
        },
        {
            id: 26,
            name: "Wires: Polycab/Havells",
            description: "New Power Points (One 6A/16A Socket+Switch is 1 Point) (cost of switch plates is not included and to be extra or provided by client).",
            unit: "Nos",
            value: venData.newPowerPoints,
            name2: "newPowerPoints"
        },
        {
            id: 27,
            name: "False Ceiling Electrical Wiring",
            description: " (Providing, laying 20 mm conduits & 1.5 Sq.mm Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing). Wires: Polycab/Havells",
            unit: "Nos",
            value: venData.falseCeilingElectricalWiring,
            name2: "falseCeilingElectricalWiring"
        },
        {
            id: 28,
            name: "Light Installation on Walls",
            description: "",
            unit: "Nos",
            value: venData.lightInstallationOnWalls,
            name2: "lightInstallationOnWalls"
        },
        {
            id: 29,
            name: "Fan Installation ",
            description: "",
            unit: "Nos",
            value: venData.fanInstallation,
            name2: "fanInstallation"
        },
        {
            id: 30,
            name: " Complete Electrical Wiring",
            description: "(Providing, laying 20 mm conduits & 1.5 Sq.mm and 2.5Sq.mm and 4 Sq.mm Copper Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing & Db if required).Wires: Polycab/Havells ",
            unit: "Sq.ft",
            value: venData.completeElectricalWiring,
            name2: "completeElectricalWiring"
        },
        {
            id: 31,
            name: "Ac Copper piping",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same. (thickness - 6 and 12mm) (upto 2 ton split ac)",
            unit: "Rft",
            value: venData.acCopperPiping,
            name2: "acCopperPiping"
        },
        {
            id: 32,
            name: "Ac 20-25 mm drain pipe",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same",
            unit: "Rft",
            value: venData.acDrainPipe,
            name2: "acDrainPipe"
        },
        {
            id: 33,
            name: "AC Installation ",
            description: "",
            unit: "Nos",
            value: venData.acInstallation,
            name2: "acInstallation"
        },
    ]
    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    useEffect(() => {
        const b = dataArr.filter((currElem) => {
            return currElem.id == showId
        })
        setOfArr(b)
    }, [showId])

    const handleEdit = async () => {
        const payload = {
            tileFlooringFixing: dataArr[0].value === "" ? 0 : dataArr[0].value,
            wallTilesFixing: dataArr[1].value === "" ? 0 : dataArr[1].value,
            dadoPlasterForWallTiles: dataArr[2].value === "" ? 0 : dataArr[2].value,
            plainCementConcrete: dataArr[3].value === "" ? 0 : dataArr[3].value,
            brickworkHalf: dataArr[4].value === "" ? 0 : dataArr[4].value,
            brickworkFull: dataArr[5].value === "" ? 0 : dataArr[5].value,
            plastering: dataArr[6].value === "" ? 0 : dataArr[6].value,
            popFalseCeiling: dataArr[7].value === "" ? 0 : dataArr[7].value,
            popCove: dataArr[8].value === "" ? 0 : dataArr[8].value,
            popPunning: dataArr[9].value === "" ? 0 : dataArr[9].value,
            gypsumFalseCeiling: dataArr[10].value === "" ? 0 : dataArr[10].value,
            gypsumCove: dataArr[11].value === "" ? 0 : dataArr[11].value,
            woodenFalseCeiling: dataArr[12].value === "" ? 0 : dataArr[12].value,
            royalPaint: dataArr[13].value === "" ? 0 : dataArr[13].value,
            premiumEmulsion: dataArr[14].value === "" ? 0 : dataArr[14].value,
            wallPanellingWithLaminate: dataArr[15].value === "" ? 0 : dataArr[15].value,
            wallPanellingWithLaminateWithHardwoodFrame: dataArr[16].value === "" ? 0 : dataArr[16].value,
            wallPanellingVeneer: dataArr[17].value === "" ? 0 : dataArr[17].value,
            mirrorPanellingPlainRectangularWithBevelled: dataArr[18].value === "" ? 0 : dataArr[18].value,
            mirrorPanellingBeveledBorderAndColoredMirror: dataArr[19].value === "" ? 0 : dataArr[19].value,
            doorWithLaminateFinish: dataArr[20].value === "" ? 0 : dataArr[20].value,
            doorChaukathFrame: dataArr[21].value === "" ? 0 : dataArr[21].value,
            lookingMirrorBathrooms: dataArr[22].value === "" ? 0 : dataArr[22].value,
            shiftingPointsOneMetre: dataArr[23].value === "" ? 0 : dataArr[23].value,
            shiftingPointsOneToFiveMetre: dataArr[24].value === "" ? 0 : dataArr[24].value,
            newPowerPoints: dataArr[25].value === "" ? 0 : dataArr[25].value,
            falseCeilingElectricalWiring: dataArr[26].value === "" ? 0 : dataArr[26].value,
            lightInstallationOnWalls: dataArr[27].value === "" ? 0 : dataArr[27].value,
            fanInstallation: dataArr[28].value === "" ? 0 : dataArr[28].value,
            completeElectricalWiring: dataArr[29].value === "" ? 0 : dataArr[29].value,
            acCopperPiping: dataArr[30].value === "" ? 0 : dataArr[30].value,
            acDrainPipe: dataArr[31].value === "" ? 0 : dataArr[31].value,
            acInstallation: dataArr[32].value === "" ? 0 : dataArr[32].value,
        }
        const res = await axios.post("https://pro-api.idesign.market/user/addContractorRateList", payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }
        ).then((res) => {
            console.warn(res)
            navigate("/myprofile")
        }).catch(err => console.warn(err))

    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    function f(n) {
        return n != '' || n != undefined
    }
    const handleEditStart = () => {
        setIsSave(true)
    }

    const isNullish = Object.values(venData).every(value => {
        if (value === '' || value === undefined) {
            return true;
        }

        return false;
    });

    return (
        <>

            <Offcanvas className="p-2" show={show2} onHide={handleClose2} placement="bottom" size="md" style={{ height: "fit-content", borderRadius: "2rem 2rem 0 0" }}>
                <div
                    className="w-100 d-flex justify-content-center mt-2"
                    onClick={handleClose2}
                >
                    <img src={closeCanvasBar} />
                </div>
                <Offcanvas.Body>
                    <div className="d-flex flex-column p-3">
                        <p className="r_false2 mb-1" style={{ fontSize: "16px", width: "90%", lineHeight: "20px" }}>{ofArr[0]?.name}</p>
                        <p className="r_desc ">{ofArr[0]?.description} </p>
                    </div></Offcanvas.Body>
            </Offcanvas>
            <div className='d-block d-md-none'>
                <Link className="d-flex align-items-center" to="/myprofile" style={{ textDecoration: "none", color: "black", height: "50px", background: "white", borderBottom: "1px solid #DFDFDF" }}>
                    <span className="d-flex align-items-center">
                        <div className="me-3 d-flex align-items-center">
                            <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                        </div>
                        <div className="page-Heading" style={{ fontWeight: "500" }}>Vendor Subscription</div>
                    </span>
                </Link>
                <div className="d-flex flex-column w-100 " style={{ background: "white", height: "90vh" }}>
                    <div className="d-flex flex-column p-4">
                        <div className="d-flex justify-content-between w-100">
                            <h2 className="ven_head" style={{ fontSize: "16px" }}>Your Rate List</h2>
                            {isSave === true ? !isNullish ? <p className="ven-edit float-end" onClick={handleEdit} style={{ fontSize: "14px" }}>Save</p> : <p className="ven-edit float-end" style={{ pointerEvents: "none", fontSize: "14px" }}>Save</p> : <p className="ven-edit float-end" onClick={handleEditStart} style={{ fontSize: "14px" }}>Edit</p>}
                        </div>
                        <div className="d-flex flex-column mt-2 p-0">
                            <div className="d-flex justify-content-between ">
                                <p className="r-sno1">S No.</p>
                                <p className="r-sno2 " style={{ width: "29%" }}>Rate List</p>
                                <p className="r-sno3 ">Unit</p>
                                <p className="r-sno4 ">Price</p>
                            </div>
                            <div className="d-flex flex-column rate_main2" style={{ height: "70vh" }}>
                                {isSave ? dataArr.map((item, index) => (
                                    <div className="d-flex justify-content-between ">
                                        <p className='r_false3' style={{ width: "9%" }}> {item.id} </p>
                                        <p className="r_false3" style={{ width: "39%" }}>{item.name}  <img src={info} alt="" className="r-info " onClick={() => handleShow2(item.id)} /></p>
                                        <p className='r_false3'> {item.unit} </p>
                                        <input type="number" className="r_input4 mb-3" name={item.name2} value={item.value} onChange={handleInput} placeholder='₹ 00.00' onKeyDown={(e) =>
                                            exceptThisSymbols.includes(e.key) && e.preventDefault()
                                        } />
                                    </div>)) : dataArr.map((item, index) => (
                                        <div className="d-flex justify-content-between ">
                                            <p className='r_false3' style={{ width: "9%" }}> {item.id} </p>
                                            <p className="r_false3" style={{ width: "39%" }}>{item.name}  <img src={info} alt="" className="r-info " onClick={() => handleShow2(item.id)} /></p>
                                           
                                            <p className='r_false3'> {item.unit} </p>
                                            {item.value ? <p className="r_input6 mb-3">₹ {item?.value?.toLocaleString('en-US')}</p> : <p className="r_input6 mb-3" style={{ color: "#888888" }}>₹ 0.00</p>}
                                        </div>))}

                            </div>
                        </div>
                        {/* {Object.keys(venData)?.length > 0 && Object.values(venData).find(f) != '' ? <button className='rate_btn4' onClick={handleEdit}>Save</button> : <button className='rate_btn4' disabled>Save</button>} */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditRateListMob