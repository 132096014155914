import React from 'react'
import styles from "./landingPageCss/landingSection6.module.css";
import img1 from "./imagesAnj/aboutUs/Frame 2610951.png";
import img2 from "./imagesAnj/aboutUs/Frame 2610952.png";
import img3 from "./imagesAnj/aboutUs/Frame 2610953.png";
import { useState,useEffect } from 'react';

const LandingSection6 = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width < 1300);
  const updateMedia = () => {
      setIsDesktop(window.screen.width < 1300);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
});

  return (
    <>
      <div className={styles.container}>
         <div className={styles.contain}>
          {isDesktop? <div className={styles.text_div1}>
              <div className={styles.text}>See who’s talking About us</div>
            </div>: <div className={styles.text_div}>
              <div className={styles.text}>See who’s <br/> talking <br/> About us</div>
            </div>}
           
           

            <div className={styles.boxes}>
            <a href="https://www.constructionworld.in/latest-construction-news/real-estate-news/idesign.market-has-developed-software-for-interior-designers/33349" target='blank'> <img src={img1} alt="" className={styles.imgs}/></a> 
            <a href="https://www.zeebiz.com/small-business/news-tech-startup-idesignmarket-launches-software-for-interior-designing-professionals-178661" target='blank'> <img src={img2} alt="" className={styles.imgs}/></a> 
            <a href="https://www.deccanherald.com/brandspot/pr-spot/navneet-jhamb-joins-as-advisor-to-tech-startup-idesignmarket-1106972.html" target='blank'><img src={img3} alt="" className={styles.imgs}/></a>  
            </div>
         </div>
      </div>
    </>
  )
}

export default LandingSection6