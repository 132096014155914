import React from "react";
import leads from "../SaasAssets/leads.svg";
import quotation from "../SaasAssets/quotation.svg";
import pmt from "../SaasAssets/pmt.svg";
import mom from "../SaasAssets/mom.svg";
import timeline from "../SaasAssets/timeline.svg";
import filemanager from "../SaasAssets/filemanager.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./landingPageCss/landingSection2.module.css";
import file from "./imagesAnj/section2/folder-5-line.svg";
import timelineee from "./imagesAnj/section2/input-cursor-move.svg";
import taskk from "./imagesAnj/section2/checkbox-multiple-line.svg";
import note from "./imagesAnj/section2/file-text-line.svg";

const LandingSection2 = () => {
  const [manage, setManage] = useState(0);
  const navigate = useNavigate();
  const handleProduct = () => {
    navigate("/products");
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.img_div}>
          <img
            src="https://idesign-office.s3.ap-south-1.amazonaws.com/backImg2%20%282%29.png"
            alt=""
            className={styles.back}
          />
          <img
            src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611058.svg"
            alt=""
            className={styles.mainImg}
          />
        </div>

        <div className={styles.content_div}>
          <div>
            <div>
              <button className={styles.btn}>#Project Management</button>
            </div>
            <div className={styles.heading}>
              Managing your project has never been <br /> this easy
            </div>
            <div className={styles.projects_div}>
              <div className={styles.file_timeline}>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img src={file} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>FILES</div>
                  <p className={styles.para}>
                    Manage and track all project files in a unified destination
                  </p>
                </div>
                <div style={{ width: "50%", paddingLeft: "2rem" }}>
                  <img src={timelineee} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>TIMELINES</div>
                  <p className={styles.para}>
                    Stay on track with your projects from <br /> start to finish
                  </p>
                </div>
              </div>
              <div className={styles.task_daily}>
                <div style={{ width: "40%" }}>
                  <img src={taskk} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>TASK MANAGER</div>
                  <p className={styles.para}>
                    Create and track tasks, material indents, issues and
                    pendencies
                  </p>
                </div>
                <div style={{ paddingLeft: "2rem", width: "50%" }}>
                  <img src={note} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>DAILY PROJECT REPORTS</div>
                  <p className={styles.para}>
                    Get daily updates from sites and <br /> design and collaborate
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection2;
