import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Spinner } from "react-bootstrap";
import notificationIcon from "../../../../../Assets/Dashboard/notification.svg";
import userIcon from "../../../../../Assets/Dashboard/usernotification.svg";
import transactionIcon from "../../../../../Assets/Dashboard/transactionnotification.svg";
import warningIcon from "../../../../../Assets/Dashboard/warningnotification.svg";
import selectIcon from "../../../../../Assets/Dashboard/selectNotification.svg";
import styles from "./styles.module.scss";
import {
  getNotifications,
  notificationActions,
  getUserProfile,
} from "../../../../../Apis";
// import {
//   NOTIFICATION_TYPE,
//   NOTIFICATION_ACTION,
// } from "../../../../../Constants";
import moment from "moment";
import "moment-duration-format";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../Router/routes";
import axios from "axios";
import { NOTIFICATION_ACTION, NOTIFICATION_TYPE } from "../../../../../Constants/enums";

const DURATION_FORMAT_ORDER = {
  asMonths: "M [months]",
  asWeeks: "W [weeks]",
  asDays: "D [d]",
  asHours: "h [h]",
  asMinutes: "m [m]",
  asSeconds: "s [s]",
};
export const formatNotificationTime = (date) => {
  const diffMilliseconds = moment(moment().diff(moment(date)));
  const duration = moment.duration(diffMilliseconds, "milliseconds");
  let format = null;
  for (const key in DURATION_FORMAT_ORDER) {
    if (duration[key]?.() >= 1) {
      format = DURATION_FORMAT_ORDER[key];
      break;
    }
  }
  return duration.format(format);
};

const Notifications = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [unread, setUnread] = useState(0);
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    setSelectedItems([]);
    // getUserProfile().then((res) => setUnread(res?.data?.unread));
    axios.get('https://pro-api.idesign.market/user/profile', {
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res)=>{setUnread(res?.data?.data?.unread)})
    .catch((err)=>{console.log(err)});
    if (show) {
      setLoading(true);
      getNotifications({ pageNo: 0 })
        .then((res) => {
          setData(res?.data?.data);
        })
        .finally(() => setLoading(false));
    }
  }, [show, reload]);

  const getIcon = (type) => {
    switch (type) {
      case NOTIFICATION_TYPE.NEW_LEAD_ASSIGN:
        return userIcon;
      case NOTIFICATION_TYPE.PLAN_EXPIRED:
        return warningIcon;
      case NOTIFICATION_TYPE.PLAN_UPGRADE:
        return transactionIcon;
      default:
        return userIcon;
    }
  };

  const markAllasRead = () => {
    notificationActions({ action: NOTIFICATION_ACTION.READ_ALL }).then((res) =>
      setReload((prev) => !prev)
    );
  };

  const onItemClick = (id, isRead, type) => {
    if (!isRead) {
      notificationActions({
        id: [id],
        action: NOTIFICATION_ACTION.READ,
      }).then((res) => setReload((prev) => !prev));
    }
    if (type === NOTIFICATION_TYPE.PLAN_UPGRADE) {
      navigate(ROUTES.MY_PROFILE);
    } else if (type === NOTIFICATION_TYPE.NEW_LEAD_ASSIGN) {
      navigate(ROUTES.LEADS);
    } else if (type === NOTIFICATION_TYPE.PLAN_EXPIRED) {
      navigate(ROUTES.GO_PREMIUM);
    }
  };

  const onItemSelect = (id) => {
    if (selectedItems.includes(id)) {
      const index = selectedItems.indexOf(id);
      if (index > -1) {
        const copyArray = [...selectedItems];
        copyArray.splice(index, 1);
        setSelectedItems(copyArray);
      }
    } else {
      setSelectedItems((prevItems) => [...prevItems, id]);
    }
  };

  const deleteItems = () => {
    notificationActions({
      action: NOTIFICATION_ACTION.DELETE,
      id: selectedItems,
    }).then((res) => setReload((prev) => !prev));
  };

  const Item = ({
    _id = "",
    title = "",
    description = "",
    type = 0,
    isRead = false,
    createdAt,
  }) => (
    <Dropdown.Item
      className={`${styles.item} ${isRead ? "" : styles.unread}`}
      onClick={() => onItemClick(_id, isRead, type)}
    >
      {/* <div onClick={(e) => e.stopPropagation()} className="me-2">
        <input
          type="checkbox"
          checked={selectedItems.includes(_id)}
          onChange={(e) => onItemSelect(e?.target?.checked, _id)}
        />
      </div> */}
      <div onClick={(e) => e.stopPropagation()}>
        <img
          className="thumbnail-image"
          src={selectedItems.includes(_id) ? selectIcon : getIcon(type)}
          alt="user pic"
          onClick={(e) => onItemSelect(_id)}
        />
      </div>
      <div className={styles.content}>
        <span>{title}</span>
        <p>{description}</p>
      </div>
      <div className={styles.time}>{formatNotificationTime(createdAt)}</div>
    </Dropdown.Item>
  );

  return (
    <DropdownButton
      size="lg"
      variant="outline-secondary"
      title={
        <div className={styles.toggleContainer}>
          {!!unread && <div className={styles.count}>{unread}</div>}
          <img
            className="thumbnail-image"
            src={notificationIcon}
            alt="user pic"
          />
        </div>
      }
      id="input-group-dropdown-1"
      onToggle={() => setShow((prev) => !prev)}
    >
      <Dropdown className={styles.container}>
        <Dropdown.Header>
          <span>Notification</span>
        </Dropdown.Header>

        <Dropdown.Divider />

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className={styles.actions}>
              {selectedItems?.length ? (
                <button onClick={deleteItems}>Delete</button>
              ) : (
                <span></span>
              )}

              {!!data.length && (
                <span onClick={markAllasRead}>Mark all as read</span>
              )}
            </div>

            <div className={styles.scrollContainer}>
              {data.length ? (
                data?.map((item) => <Item key={item?._id} {...item} />)
              ) : (
                <div className="text-center">No new notifications</div>
              )}
            </div>
          </>
        )}
      </Dropdown>
    </DropdownButton>
  );
};

export default Notifications;
