import React from "react";
import mic from "../SaasAssets/mic.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import styles from "./landingPageCss/landingSection4.module.css";
import icon1 from "./imagesAnj/section 4/buy.png";
import icon2 from "./imagesAnj/section 4/profile.png";
import icon3 from "./imagesAnj/section 4/profilered.png";
// import img3 from "./imagesAnj/section 4/Sec3.png";
// import back from "./imagesAnj/section 4/mainImg.png";
import img4 from "./imagesAnj/section 4/Frame 2611060.svg";

const LandingSection4 = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.img_div}>
          <img
            src="https://idesign-office.s3.ap-south-1.amazonaws.com/mainImg.png"
            alt=""
            className={styles.back}
          />
          <img
            src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611060.svg"
            alt=""
            className={styles.mainImg}
          />
        </div>

        <div className={styles.content_div}>
          <div style={{ paddingLeft: "8rem" }}>
            <div>
              <button className={styles.btn}>#Lead Management</button>
            </div>
            <div className={styles.heading}>
              Stay ahead of the competition,nurture <br /> your leads
            </div>
            <div className={styles.projects_div}>
              <div className={styles.file_timeline}>
                <div style={{ width: "50%" }}>
                  <img src={icon3} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>MANAGE LEADS</div>
                  <p className={styles.para}>
                    Effortlessly manage your interior <br /> design projects
                  </p>
                </div>

                <div style={{ width: "50%", paddingLeft: "2rem" }}>
                  <img src={icon2} alt="" className={styles.icon} />
                  <div className={styles.icon_name}>PROFILE LISTING</div>
                  <p className={styles.para}>
                    Stay on track with your projects from start to finish
                  </p>
                </div>
              </div>
              <div className={styles.task_daily}>
                <div style={{ width: "50%" }}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/password.svg"
                    alt=""
                    className={styles.icon}
                  />
                  <div className={styles.icon_name}>CLIENT LOGIN</div>
                  <p className={styles.para}>
                    Better project updates with login <br /> for your clients.
                  </p>
                </div>

                <div style={{ width: "50%", paddingLeft: "2rem" }}>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/priority.svg"
                    alt=""
                    className={styles.icon}
                  />
                  <div className={styles.icon_name}>CLIENT SIGN OFF</div>
                  <p className={styles.para}>
                    Get approvals from your clients in <br />
                    minutes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection4;
