import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import HeaderNav from '../Lms/LmsWebComponents/HeaderNav/HeaderNav'
import SidebarWebNew from '../Lms/LmsWebComponents/SidebarNew/SidebarWebNew'
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
import clock from './VendorImgs/Clock.svg'
import hourglass from './VendorImgs/hourglass.svg'
import VendorReqApprovedMob from './VendorReqApprovedMob'

const VendorReqApproved = () => {
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
    const navigate = useNavigate()
    const [data1, setData1] = useState([])
    const handleRequest = () => {
        navigate("/myprofile")
    }
    // const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo[0])
    const profileInfo = async () => {
        const remM = await axios.get("https://pro-api.idesign.market/user/profile", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        })
        setData1(remM?.data?.data)
    }
        useEffect(() => {
        profileInfo()
    }, [])
    // console.log(data1)

    return (
        <>
        <div className='d-none d-md-block'>
            <HeaderNav />
            <div className=" d-flex w-100">
                <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", width: "22vw", borderRight: "1px solid #A7A7A7", paddingRight: "0" }}>
                    <SidebarWebNew />
                </div>
                <div className="d-flex w-100" style={{ background: "white" }}>
                    <div className="d-flex flex-column p-5">
                        <div className='d-flex'>
                            <img src={clock} alt="" style={{ height: "150px", width: "150px" }} />
                            <img src={hourglass} alt="" style={{ marginBottom: "auto", height: "50px", width: "50px" }} />
                        </div>
                        <div className="d-flex flex-column py-4">
                            <h3 className="list_head">Get Started with just 3 easy steps</h3>
                                <span className="list_start2"> We will review your profile and rate list, once approved will get confirmation on your registered mail Id {profileInfo[0]?.data?.data?.email}</span>
                            <span className="list_start2 mt-4"> Kindly wait for some time, we will contact you after your evaluation</span>
                            <button className='ven_btn mt-5' onClick={handleRequest}>Go to Profile</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <VendorReqApprovedMob/>
        </>
    )
}

export default VendorReqApproved