import React from "react";
import styles from "./landingPageCss/landingSection5.module.css";
import danny from "./imagesAnj/testimonal/Ellipse 957.png";
import rachit from "./imagesAnj/testimonal/Ellipse 958.png";
import bhavya from "./imagesAnj/testimonal/Ellipse 959.png";

import { useState } from "react";

const LandingSection5 = () => {
  return (
    <>
      <div className={styles.contain}>
        <div className={styles.testi_heading}>TESTIMONIALS</div>
        <div className={styles.head_para}>
          Hear from the people that use our product to streamline their <br />
          process and manage their projects
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
            marginTop: "1.5rem",
          }}
        >
          <div className={styles.testi_contain}>
            <div>
              <img src={danny} alt="" className={styles.pics} />
            </div>
            <div>
              <div className={styles.name}>Rishi</div>
              <p className={styles.person_job}>Designer Head</p>
              <p className={styles.long_text}>
                iDesign.Market is a great platform for businesses to connect,
                grow and support each other. It provides accurate results
                quickly and has a very user-friendly interface.
              </p>
            </div>
          </div>
          <div className={styles.testi_contain}>
            <div>
              <img src={rachit} alt="" className={styles.pics} />
            </div>
            <div>
              <div className={styles.name}>Mayank</div>
              <p className={styles.person_job}>Idesign Studio Head</p>
              <p className={styles.long_text}>
              Queries from idesign have been very frequent within my team.They are very clear with the ideas and time line .It's a great experience working for them
              </p>
            </div>
          </div>
          <div className={styles.testi_contain}>
            <div>
              <img src={bhavya} alt="" className={styles.pics} />
            </div>
            <div>
              <div className={styles.name}>Dikshant Negi</div>
              <p className={styles.person_job}>
              Design Lead
              </p>
              <p className={styles.long_text}>
              Working as a supplier of furnishing fabrics to I-Design has been an absolute pleasure.Extremely professional team at the helm and an exemplary track record on payments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection5;
