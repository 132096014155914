import React from "react";
import MainHead from "../AboutPage/MainHead";
import WhatWeDo from "../AboutPage/WhatWeDo";
import Stories from "../AboutPage/Stories";
import LeaderShip from "../AboutPage/LeaderShip";
import WeWork from "../AboutPage/WeWork";
import LoginHeader from "../../LoginHeader";
import Footer from "../../PricingPage/Footer";
import Awards from "./Awards";
import Backed from "./Backed";

const AboutSec1 = () => {
  return (
    <>
      <div>
        <LoginHeader/>
        <MainHead />
        <WhatWeDo />
        {/* <Stories /> */}
        <LeaderShip />
        <Awards/>
        <Backed/>
        <WeWork/>
        <Footer/>
      </div>
    </>
  );
};

export default AboutSec1;
